const HEX2RGB = (hex) => {
  if (hex.charAt(0) === '#') {
    hex = hex.substr(1);
  }
  if ((hex.length < 2) || (hex.length > 6)) {
    return false;
  }
  const values = hex.split('');
  let r;
  let g;
  let b;

  if (hex.length === 2) {
    r = parseInt(values[0].toString() + values[1].toString(), 16);
    g = r;
    b = r;
  } else if (hex.length === 3) {
    r = parseInt(values[0].toString() + values[0].toString(), 16);
    g = parseInt(values[1].toString() + values[1].toString(), 16);
    b = parseInt(values[2].toString() + values[2].toString(), 16);
  } else if (hex.length === 6) {
    r = parseInt(values[0].toString() + values[1].toString(), 16);
    g = parseInt(values[2].toString() + values[3].toString(), 16);
    b = parseInt(values[4].toString() + values[5].toString(), 16);
  } else {
    return false;
  }
  const isLight = (r * 0.213 + g * 0.715 + b * 0.072) > 186;
  return {
    color: [r, g, b],
    isLight,
  };
};

export default HEX2RGB;
