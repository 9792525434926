<template lang="pug">
  v-container(grid-list-md)
    //- h2.news-title Последние новости
    //- .news-title.mb-4 в мире дополнительного образования

    v-layout(row wrap).news-wrapper
      v-flex.new(
        xs12 sm6
        :md4="!checkLarge(idx)"
        :md8="checkLarge(idx)"
        v-for="(item, idx) in items" :key="item.id"
      )
        router-link(
          :to="{ name: 'new', params: { id: item.id }}"
          v-bind:class="['card', { 'new--dark' : item.isLight, 'large': checkLarge(idx) }]"
        )
          .new__preview
            v-img.image(
              :src="item.thumb_url || item.image_1_url"
              :lazy-src="require('@/assets/images/placeholder-news.svg')"
            )
          .new__content(:style="getGradientStyle(item, idx)")
            .new-overlay
            .new-title
              router-link.primary--text(:to="{ name: 'new', params: { id: item.id }}")
                .title {{ item.caption }}
            .new-text
              p {{ item.text_short }}
            div.news-footer
              p.new-date
                v-icon.marks-icon date_range
                |  {{ item.date_publication }}
              .new-showed(v-if="item.count_view > 100" :title="`${item.count_view} просмотров`")
                v-icon.marks-icon.mr-1(small) visibility
                span {{ item.count_view }}

    v-btn.show-more.mx-auto.my-4(
      v-if="nextPage"
      outlined
      text
      color="primary"
      @click="allowAppendItems"
      :loading="loading"
    ) Показать ещё

</template>


<script>
import HEX2RGB from '../utils/hex2rgb';

import TheNews from '../components/TheNews';
import NewsService from '@/services/news';

export default {
  name: 'NewsItems',
  components: { TheNews },
  data() {
    return {
      isLargeFirst: true,
      nextPage: 1,
      items: [],
      loading: false,
    };
  },
  computed: {
    wideSizes() {
      return { xs12: true, sm12: true, md12: true };
    },
  },
  created() {
    this.allowAppendItems();
  },
  methods: {
    allowAppendItems() {
      this.loading = true;
      const expand = { expand: 'additional_links,image_color,thumb_url,image_1_url' };
      const perPage = { 'per-page': 10 };
      this.$rHandler(
        NewsService.getNews(this.nextPage, { ...expand, ...perPage }),
        (response) => {
          this.items = this.items.concat(response.data.data);

          if (+response.headers['x-pagination-page-count'] <= +response.headers['x-pagination-current-page']) {
            this.nextPage = 0;
          } else {
            this.nextPage += 1;
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
    },
    getGradientStyle(item, idx) {
      // задаем градиент в зависимотси от цвета
      if (!item.image_color) return '';
      const color = HEX2RGB(item.image_color).color.join(',');
      const { isLight } = HEX2RGB(item.image_color);
      this.$set(item, 'isLight', isLight);
      if (this.checkLarge(idx)) {
        return `background:linear-gradient(to right, rgba(${color}, 1) 77%, rgba(${color}, 0.8) 87%, rgba(${color}, 0) 100%);`;
      }
      return `background-image:linear-gradient(to top, rgba(${color}, 1) 60%, rgba(${color}, 0.6) 80%, rgba(${color}, 0) 100%)`;
    },
    checkLarge(idx) {
      // проверка большого размера будет блок или обычного размера
      if (window.innerWidth <= 960) {
        return false;
      }
      idx += 1;
      return (idx % 10) === 4 || (idx % 10) === 7;
    },
  },
};
</script>

<style lang="scss" scoped>
.news-title {
  text-transform: uppercase;
  color: $primary-color;
}

.news-wrapper {
  width: 100%;

  .new {
    display: flex;

    .card {
      background-color: white;
      padding-top: 100%;
      overflow: hidden;
      box-sizing: border-box;
      position: relative;
      height: 0;
      width: 100%;

      &:hover {
        .new__preview {
          opacity: .9;
        }
      }

      .new__preview {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        transition: 0.3s;

        .image {
          height: 90%;
        }
      }

      .new__content {
        padding: 40px 16px 10px;
        background-image:linear-gradient(to top, rgb(42,74,127) 60%, rgba(42,74,127, 0.7) 80%, rgba(42,74,127, 0) 100%);
        position: absolute;
        flex-direction: column;
        display: flex;
        bottom: 0;
        width: 100%;

        .new-title {
          margin-top: auto;
          margin-bottom: 16px;
          line-height: 1.2;
        }

        .new-text p {
          margin-bottom: 8px;
        }

        .new-title,
        .new-text,
        .news-footer,
        .new-doplink{
          position: relative;
          z-index: 2;
        }

        .title, .new-text p, .new-date, .v-icon {
          color: #F8F8F8;
        }

        .new-overlay {
          width: 100%;
          height: 65%;
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;
          opacity: 0.5;
          backdrop-filter: blur(10px);
        }

        .news-footer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 14px;
          border-color: rgba(#F8F8F8, .3);

          .new-date{
            justify-self: center;
            margin: 0 10px 0 0;
            font-size: 12px;

            i {
              font-size: 16px;
            }
          }

          .new-showed {
            color: $light-color;
            text-align: right;
            font-size: 13px;
          }
        }

      }

    }

    .new--dark .new__content {
      .title, .new-text p, .new-date, .v-icon {
        color: #4C505F;
      }

      .news-footer {
        border-color: rgba($dark-color, .3);
        .new-showed {
          color: #4C505F;
        }
      }
    }
  }
}

.show-more {
  width: calc(100% - 8px);
  border: 0;
}

@media (min-width: 960px) {
  .news-wrapper .new .large {
    padding-top: calc(50% - 4px);
    display: flex;

    .new__preview {
      width: 60%;
      right: 0;

      .image {
        height: 100%;
      }
    }

    .new__content {
      padding: 16px 40px 10px 16px;
      width: calc(50% + 40px);
      height: 100%;
      background:linear-gradient(to right, rgb(42,74,127) 77%, rgba(42,74,127, 0.7) 87%, rgba(42,74,127, 0) 100%);

      .new-overlay {
        height: 100%;
        width: 75%;
      }

      .new-title {
        margin-top: 20px;
      }

      .new-text {
        flex: 1 0 auto;
      }
    }
  }
}

</style>
